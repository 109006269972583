import { corporateGroupTableConfigs } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  laborTotalCostPercent: 'percent',
  netSalesWeeklyAverage: 'price',
} as const

const useBurgerworksSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales',
      'labor_total_cost_percent',
      'net_sales_weekly_average',
    ],
  },
})

export default useBurgerworksSystemwideOverview
